<template>
    <div>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
            </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }" :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
          enabled: true,
          perPage: pageLength
        }">
            <template slot="table-row" slot-scope="props">

                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                    <b-avatar :src="props.row.logo" class="mx-1" />
                    <span class="text-nowrap">{{ props.row.name }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                        {{ props.row.status }}
                    </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                    <span>
                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                            </template>
                            <b-dropdown-item @click="showEditModal(props.row)">
                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                <span>Editar</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="showDeleteModal(props.row)">
                                <feather-icon icon="TrashIcon" class="mr-50" />
                                <span>Eliminar</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                            last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-modal.modal-container variant="gradient-success"
            class="btn-icon rounded-circle">
            <feather-icon icon="PlusIcon" />
        </b-button>

        <!--Modal container-->
        <b-modal id="modal-container" class="position-relative" ref="modal-container" cancel-variant="outline-secondary"
            ok-title="Guardar" cancel-title="Cerrar" centered :title="mainModalTitle" @ok="handleOk" @hidden="hideModal"
            :busy="overlay" size="lg">
            <b-overlay :show="overlay" rounded="sm">
                <validation-observer ref="validationRules">
                    <b-form @submit.stop.prevent="validateForm">
                        <b-row>
                            <b-col md="6">
                                <b-col cols="12">
                                    <b-form-group for="type" label-cols-md="4" label="Tipo">
                                        <validation-provider #default="{ errors }" ref="txtTypeProvider" name="Tipo"
                                            rules="required">
                                            <b-input-group class="input-group-merge" :class="isTypeValid.value">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="CodesandboxIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input ref="type" placeholder="Tipo" v-model="type"
                                                    @input="validateField(type,isTypeValid)"
                                                    @blur="validateField(type, isTypeValid)"
                                                    :state="errors.length > 0 ? false:null" />
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-form-group for="tara" label-cols-md="4" label="Tara">
                                        <validation-provider #default="{ errors }" ref="txtTaraProvider" name="Tara"
                                            rules="required">
                                            <b-input-group class="input-group-merge" :class="isTaraValid.value">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="EditIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input id="tara" placeholder="Tara (Kg)" v-model="tareWeight"
                                                    :state="errors.length > 0 ? false:null" type="number"
                                                    @input="validateField(tareWeight,isTaraValid)"
                                                    @blur="validateField(tareWeight, isTaraValid)" />
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-form-group for="weight" label-cols-md="4" label="Peso">
                                        <validation-provider #default="{ errors }" ref="txtWeightProvider" name="Peso"
                                            rules="required">
                                            <b-input-group class="input-group-merge" :class="isWeightValid.value">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="EditIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input ref="weight" placeholder="Peso (Kg)" v-model="weight"
                                                    :state="errors.length > 0 ? false:null" type="number"
                                                    @input="validateField(weight,isWeightValid)"
                                                    @blur="validateField(weight, isWeightValid)" />
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-form-group for="volume" label-cols-md="4" label="Volumen">
                                        <validation-provider #default="{ errors }" ref="txtVolumenProvider"
                                            name="Volumen" rules="required">
                                            <b-input-group class="input-group-merge" :class="isVolumenValid.value">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="EditIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input ref="volume" placeholder="Volumen (Mts³)" v-model="volume"
                                                    :state="errors.length > 0 ? false:null" type="number"
                                                    @input="validateField(volume,isVolumenValid)"
                                                    @blur="validateField(volume, isVolumenValid)" />
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-col>

                            <b-col md="6">
                                <b-col cols="12">
                                    <b-form-group for="large" label-cols-md="4" label="Largo">
                                        <validation-provider #default="{ errors }" ref="txtLargeProvider" name="Largo"
                                            rules="required">
                                            <b-input-group class="input-group-merge" :class="isLargeValid.value">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="EditIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input ref="large" placeholder="Largo (Mts)" v-model="long"
                                                    :state="errors.length > 0 ? false:null" type="number"
                                                    @input="validateField(long,isLargeValid)"
                                                    @blur="validateField(long, isLargeValid)" />
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-form-group for="ancho" label-cols-md="4" label="Ancho">
                                        <validation-provider #default="{ errors }" ref="txtWidthProvider" name="Ancho"
                                            rules="required">
                                            <b-input-group class="input-group-merge" :class="isWidthValid.value">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="EditIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input ref="ancho" placeholder="Ancho (Mts)" v-model="width"
                                                    :state="errors.length > 0 ? false:null" type="number"
                                                    @input="validateField(width,isWidthValid)"
                                                    @blur="validateField(width, isWidthValid)" />
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-form-group for="height" label-cols-md="4" label="Alto">
                                        <validation-provider #default="{ errors }" ref="txtHeightProvider" name="Alto"
                                            rules="required">
                                            <b-input-group class="input-group-merge" :class="isHeightValid.value">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="EditIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input placeholder="Alto (Mts)" v-model="height"
                                                    :state="errors.length > 0 ? false:null" type="number"
                                                    @input="validateField(height,isHeightValid)"
                                                    @blur="validateField(height, isHeightValid)" />
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-form-group for="observation" label="Observacion" label-cols-md="4">
                                        <validation-provider #default="{ errors }" ref="txtObservationProvider"
                                            name="Observacion" rules="required">
                                            <b-input-group class="input-group-merge" :class="isObservationValid.value">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="EditIcon" />
                                                </b-input-group-prepend>
                                                <b-form-textarea id="observation" rows="2" placeholder="Observacion"
                                                    v-model="observation" :state="errors.length > 0 ? false:null"
                                                    @input="validateField(observation,isObservationValid)"
                                                    @blur="validateField(observation, isObservationValid)" />
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-overlay>
        </b-modal>

        <b-modal id="modal-danger" ref="modal-delete" ok-variant="danger" ok-title="Si" cancel-title="No"
            modal-class="modal-danger" centered title="Eliminar" @ok="deleteContainer" :busy="deleteOverlay">
            <b-overlay :show="deleteOverlay" rounded="sm">
                <b-card-text>
                    Esta seguro que desea eliminar el contenedor {{this.type}}?
                </b-card-text>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BOverlay,
    BModal, VBModal, BForm, BRow, BCol, BFormTextarea, BCardText, BFormFile, BImg, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import CardBasicColumns from '../card/card-basic/CardBasicColumns.vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { required } from '@validations'
import en from "vee-validate/dist/locale/en.json"
import es from "vee-validate/dist/locale/es.json"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

localize({
    en,
    es
});
localize("es");

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        CardBasicColumns,
        BButton,
        BModal,
        BForm,
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BFormTextarea,
        BCardText,
        BFormFile,
        BImg,
        BInputGroup,
        BInputGroupPrepend,
        ValidationProvider,
        ValidationObserver,
        BFormInvalidFeedback,
        BOverlay,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    data() {
        return {
            isTypeValid:{
                value:null
            },
            isTaraValid:{
                value:null
            },
            isWeightValid:{
                value:null
            },
            isLargeValid:{
                value:null
            },
            isHeightValid:{
                value:null
            },
            isVolumenValid:{
                value:null
            },
            isWidthValid:{
                value:null
            },
            isObservationValid:{
                value:null
            },
            overlay: false,
            deleteOverlay: false,
            mainModalTitle: "Nuevo Contenedor",
            idContainer: 0,
            pageLength: 5,
            dir: false,
            columns: [
                {
                    label: 'Tipo',
                    field: 'type',
                },
                {
                    label: 'Peso(Kg)',
                    field: 'weight'
                },
                {
                    label: 'Volumen (Mts³)',
                    field: 'volume',
                },
                {
                    label: 'Observación',
                    field: 'observation'
                },
                {
                    label: 'Acciones',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            type: "",
            width: "",
            height: "",
            long: "",
            weight: "",
            volume: "",
            tareWeight: "",
            observation: "",
        }
    },
    computed:{
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.getContainers()
    },
    methods:{
        async getContainers(){
            const path = '/container/'
            await axios.get(path).then(response => {
                this.rows = response.data
            })
        },
        async createContainer(){
            const path = `/container/`
            await axios.post(path, {
                type: this.type,
                width: this.long,
                high: this.height,
                long: this.width,
                weight: this.weight,
                volume: this.volume,
                tareWeight: this.tareWeight,
                observation: this.observation,
            })
            .then((response) => {
                //console.log(response.data.results)
                this.getContainers()
                this.hideModal()
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.mainModalTitle,
                        icon: 'InfoIcon',
                        variant: 'success',
                        text: `El contenedor ${this.type} ha sido creado con exito!!!`,
                    },
                })
            })
            .catch((error) => {
                console.log(error)
            })
        },
        showEditModal(container) {
            this.mainModalTitle = `Contenedor ${container.type}`
            this.idContainer = container.id
            this.type = container.type
            this.long = container.width
            this.height = container.high
            this.width = container.long
            this.weight = container.weight
            this.volume = container.volume
            this.tareWeight = container.tareWeight
            this.observation = container.observation
            this.showModal()
        },
        async updateContainer(){
            const path = `/container/${this.idContainer}/`
            await axios.patch(path, {
                type: this.type,
                width: this.long,
                high: this.height,
                long: this.width,
                weight: this.weight,
                volume: this.volume,
                tareWeight: this.tareWeight,
                observation: this.observation,
            }).then((response) => {
                this.getContainers()
                this.hideModal()
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Contenedor editado',
                        icon: 'InfoIcon',
                        variant: 'success',
                        text: `El contenedor ${this.type} se ha actualizado con exito!!!`,
                    },
                })
            }).catch((error) => {
                console.log(error)
            })
        },
        async validateForm() {
            const txtTypeValid = await this.$refs.txtTypeProvider.validate().then(value => { return value.valid })
            const txtTaraValid = await this.$refs.txtTaraProvider.validate().then(value => { return value.valid })
            const txtWeightValid = await this.$refs.txtWeightProvider.validate().then(value => { return value.valid })
            const txtVolumenValid = await this.$refs.txtVolumenProvider.validate().then(value => { return value.valid })
            const txtLargeValid = await this.$refs.txtLargeProvider.validate().then(value => { return value.valid })
            const txtWidthValid = await this.$refs.txtWidthProvider.validate().then(value => { return value.valid })
            const txtHeightValid = await this.$refs.txtHeightProvider.validate().then(value => { return value.valid })
            const txtObservationValid = await this.$refs.txtObservationProvider.validate().then(value => { return value.valid })

            this.validateField(this.type, this.isTypeValid)
            this.validateField(this.tareWeight, this.isTaraValid)
            this.validateField(this.weight, this.isWeightValid)
            this.validateField(this.volume, this.isVolumenValid)
            this.validateField(this.long, this.isLargeValid)
            this.validateField(this.width, this.isWidthValid)
            this.validateField(this.height, this.isHeightValid)
            this.validateField(this.observation, this.isObservationValid)

            if (txtTypeValid && txtTaraValid && txtWeightValid && txtVolumenValid && 
                txtLargeValid && txtWidthValid && txtHeightValid && txtObservationValid){    
                if(this.idContainer>0){
                    this.updateContainer()
                }else{
                    this.createContainer()
                }
            }    
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.validateForm()
        },
        showDeleteModal(container) {
            this.idContainer = container.id
            this.type = container.type
            this.$refs['modal-delete'].show()
        },
        async deleteContainer(){
            const path = `/container/${this.idContainer}/`
            await axios.delete(path).then((response) => {
                this.getContainers()
                this.idContainer = 0
                this.type = ""
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Eliminado',
                        icon: 'Trash2Icon',
                        variant: 'danger',
                        text: 'El contendor ha sido eliminado con exito!!!',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
            })
        },
        validateField(value, validationField) {
            return value.length == 0 ? validationField.value = 'is-invalid' : validationField.value = null
        },
        hideModal() {
            this.$refs['modal-container'].hide()
            this.resetModal()
        },
        showModal() {
            this.$refs['modal-container'].show()
        },
        resetModal() {
            this.mainModalTitle = "Nuevo Contenedor"
            this.idContainer = 0
            this.type = ""
            this.width = ""
            this.height = ""
            this.long = ""
            this.weight = ""
            this.volume = ""
            this.tareWeight = ""
            this.observation = ""

            this.isTypeValid.value = null
            this.isTaraValid.value = null
            this.isWeightValid.value = null
            this.isLargeValid.value = null
            this.isWidthValid.value = null
            this.isVolumenValid.value = null
            this.isHeightValid.value = null
            this.isObservationValid.value = null
        },
            
    }
}
</script>

<style lang="scss">
    .add-button{
    float: right;
  }
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>